import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import shuffle from 'lodash/shuffle';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Testimonials = ({ data }) => {
    const shuffleData = shuffle([...data]);

    return (
        <Layout>
            <SEO
                title="Testimonials | J Clarke Fitness"
                description="Read testimonials from people who have transformed their lives with J Clarke Fitness through online coaching and personalised workout &amp; meal plans."
            />

            <div className="content">
                <div>
                    <h1>Testimonials</h1>

                    <div className="masonry">
                        {shuffleData.map((e, i) => {
                            const { image, name, duration, testimonial } = e;
                            return (
                                <div key={i} className="item shadow-lg">
                                    <Img fluid={image} alt={name} imgStyle={{ objectFit: 'contain' }} />
                                    <div>
                                        <h2>{name}</h2>
                                        <q>{testimonial}</q>
                                        <span>({duration})</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <style jsx>{`
                h1 {
                    text-align: center;
                }

                .content {
                    background-color: rgba(38, 50, 56, 0.04);

                    & > div {
                        max-width: 1200px;
                        width: 100%;
                        padding: 32px 24px 40px;
                        box-sizing: border-box;
                        margin: 0 auto;
                    }
                }

                .masonry {
                    margin-top: 40px;
                    column-count: 1;
                    column-gap: 32px;
                }

                .item {
                    background-color: #fff;
                    max-width: 500px;
                    border-radius: 3px;
                    display: block;
                    margin: 0 auto 30px;
                    width: 100%;

                    & > :global(.gatsby-image-wrapper) {
                        border-top-left-radius: 3px;
                        border-top-right-radius: 3px;
                    }

                    & > div {
                        padding: 32px 24px;

                        & h2,
                        & q,
                        & span {
                            text-align: center;
                        }

                        & q {
                            display: block;
                            font-style: italic;
                            margin: 8px auto;
                        }

                        & h2 {
                            font-size: 16px;
                            line-height: 24px;
                            margin: 0;
                            display: block;
                        }

                        & span {
                            margin: 0;
                            font-size: 12px;
                            line-height: 24px;
                            font-style: normal;
                            display: block;
                            opacity: 0.6;
                            text-transform: uppercase;
                        }
                    }
                }

                @media (min-width: 640px) {
                    .masonry {
                        column-count: 2;
                    }

                    .item {
                        display: inline-block;
                    }
                }

                @media (min-width: 800px) {
                    .content > div {
                        padding: 32px 32px 40px;
                    }
                }

                @media (min-width: 900px) {
                    .masonry {
                        column-count: 3;
                    }
                }
            `}</style>
        </Layout>
    );
};

const testimonialQuery = graphql`
    query TestinomialsQuery {
        allMarkdownRemark(filter: {frontmatter: {collection: {eq: "testimonials"}}}) {
            nodes {
                frontmatter {
                    duration
                    name
                    testimonial
                    image {
                        childImageSharp {
                            fluid(maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default props => (
    <StaticQuery
        query={testimonialQuery}
        render={data => {
            const { nodes } = data.allMarkdownRemark;

            const newData = nodes.map(({ frontmatter }) => {
                return {
                    ...frontmatter,
                    image: frontmatter.image.childImageSharp.fluid,
                };
            });

            return <Testimonials {...props} data={newData} />;
        }}
    />
);
